<template>
  <div v-for="choice in item.choices" :key="choice">
    <choice-item
      :choice="choice"
      @chosen="setChoice(choice.value)"
      @isActive="activated = choice.value"
      :activated="activated === choice.value"
    />
  </div>
</template>

<script>
import ChoiceItem from '@/components/survey/ChoiceItem';

export default {
  name: 'ChoiceType',
  props: ['item'],
  emits: ['setChoice'],
  components: {
    ChoiceItem,
  },
  data() {
    return {
      activated: null,
      choice: null,
    };
  },
  methods: {
    setChoice(value) {
      this.$emit('setChoice', value);
    },
  },
};
</script>
